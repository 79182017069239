export function common() {

  // タブレット表示
  jQuery(function($){
    var ua = navigator.userAgent;
    var viewport = document.querySelector("meta[name=viewport]");
    if((ua.indexOf('iPhone') > 0) || ua.indexOf('iPod') > 0 || (ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0)){
        viewport.setAttribute("content", "width=device-width,initial-scale=1");
    } else {
        viewport.setAttribute("content", "width=1030");
    }
  });

  // スマホtel link
  if (navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)) {
    jQuery(function($) {
        $('.tel').each(function() {
            var str = $(this).html();
            if ($(this).children().is('img')) {
                $(this).html($('<a>').attr('href', 'tel:' + $(this).children().attr('alt').replace(/-/g, '')).append(str + '</a>'));
            } else {
                $(this).html($('<a>').attr('href', 'tel:' + $(this).text().replace(/-/g, '')).append(str + '</a>'));
            }
        });
    });
  }

  //正確なvhの取得
  /*-
  使い方: calc(var(--vh, 1vh) * 100);
  -*/
  function setHeight() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  setHeight();//初期化
  window.addEventListener('resize', setHeight);// 再計算

  
  jQuery(function ($) {

    //ブレイクポイント画像切り替え
    var $elem = jQuery('.sp_img');
    var sp = '_sp.';
    var pc = '_pc.';
    var replaceWidth = 767; //ブレイクポイント指定

    function imageSwitch() {
      var windowWidth = parseInt(jQuery(window).width()); //ウィンドウサイズ取得
      $elem.each(function () {
          var $this = $(this);
          if (windowWidth >= replaceWidth) {
              $this.attr('src', $this.attr('src').replace(sp, pc));
          } else {
              $this.attr('src', $this.attr('src').replace(pc, sp));
          }
      });
    }
    imageSwitch();

    // リサイズの実行タイミング設定
    var delayStart;
    var delayTime = 200;
    jQuery(window).on('resize', function () {
    clearTimeout(delayStart);
      delayStart = setTimeout(function () {
        imageSwitch();
      }, delayTime);
    });



    //ヘッダーの高さに連動
    function headerHeightSwitch() {
      var headerHeight = jQuery('.l-header').outerHeight(); //ウィンドウサイズ取得
      jQuery('.l-main_inner').css({'padding-top': headerHeight});
      jQuery('.anchor').css({'padding-top': headerHeight});
      jQuery('.anchor').css({'margin-top': -headerHeight});
    }
    headerHeightSwitch();
    jQuery(window).on('resize', function () {
      headerHeightSwitch();
    });

    //navi SP
    //bodyのスクロール位置の格納
    var state = false;
    var scrollpos;
    $('#toggle').click(function(){
        if($(this).hasClass('active')){
            $(this).removeClass('active');
            $('.l-header').removeClass('active');
            $('.l-header_navArea').fadeOut(400);
        }else{
            $(this).addClass('active');
            $('.l-header').addClass('active');
            $('.l-header_navArea').fadeIn(400);
        }
        if(state == false) {
          scrollpos = $(window).scrollTop()
          $('body').addClass('fixed').css({'top': -scrollpos})
          state = true;
        } else {
            $('body').removeClass('fixed').css({'top': 0}),
            window.scrollTo( 0 , scrollpos )
            state = false;
        }
    });
    $('.menu-item > a').click(function() {
        if($('.l-header').hasClass('active')){
            $('.l-header_navArea').fadeOut(400);
            $('.l-header').removeClass('active');
            $('#toggle').removeClass('active');
            if(state == false) {
              scrollpos = $(window).scrollTop()
              $('body').addClass('fixed').css({'top': -scrollpos})
              state = true;
            } else {
                $('body').removeClass('fixed').css({'top': 0}),
                window.scrollTo( 0 , scrollpos )
                state = false;
            }
        }
    });
    $('.nav_close').click(function() {
        if($('.l-header').hasClass('active')){
            $('.l-header_navArea').fadeOut(400);
            $('.l-header').removeClass('active');
            $('#toggle').removeClass('active');
            if(state == false) {
                scrollpos = $(window).scrollTop()
                $('body').addClass('fixed').css({'top': -scrollpos})
                state = true;
            } else {
                $('body').removeClass('fixed').css({'top': 0}),
                window.scrollTo( 0 , scrollpos )
                state = false;
            }
        }
    });

    //scroll_header
    var startPos = 0,winScrollTop = 0;
    var headerHight = jQuery('.l-header_inner').outerHeight();
    jQuery(window).on('scroll',function(){
        winScrollTop = $(this).scrollTop();
        if (jQuery(this).scrollTop() > headerHight) {
          if (winScrollTop >= startPos) {
            jQuery('.scroll_header').addClass('hide').css({top: -headerHight});
          } else {
            jQuery('.scroll_header').removeClass('hide').css({top: 0});
          }
          startPos = winScrollTop;
      }
    });


    //js-scroll
    var wH = $(window).height();
    var EffectH = wH/5*1;
    jQuery(window).on('scroll load', function() {
      var scTop = $(this).scrollTop();
      var scBottom = scTop + $(this).height();
      var effectPos = scBottom - EffectH;
      jQuery('.js-scroll, .js-scroll-delay').each( function() {
        var thisPos = $(this).offset().top;
        if ( thisPos < effectPos ) {
          $.when(
            jQuery(this).addClass("show")
          ).done(function() {
            jQuery(this).delay(250).queue(function(){
                jQuery(this).addClass("done")
            })
          });
        }
      });
    });


    //pageTop
    $(window).bind("scroll", function() {
      if (window.matchMedia('(max-width: 767px)').matches) {
        if ($(this).scrollTop() > 300) { 
          $("#pageTop").addClass('active');
        } else {
          $("#pageTop").removeClass('active');
        }
      }else{
        if ($(this).scrollTop() > 500) { 
          $("#pageTop").addClass('active');
        } else {
          $("#pageTop").removeClass('active');
        }
      }
    });

    //floating
    $(window).bind("scroll", function() {
      if (window.matchMedia('(max-width: 767px)').matches) {
        if ($(this).scrollTop() > 300) { 
          $("#floating").addClass('active');
        } else {
          $("#floating").removeClass('active');
        }
      }else{
        if ($(this).scrollTop() > 500) { 
          $("#floating").addClass('active');
        } else {
          $("#floating").removeClass('active');
        }
      }
      close
    });
    $('#floating .close').on('click',function () {
      $('#floating').fadeOut();
    });
    

    //anchor link
    // $('a[href^="#"]').on('click', function () {
    //   smooth(this);
    //   return false;
    // });

    // //pageTop
    // $('#pageTop').on('click',function () {
    //   animateoffset(0);
    //   return false;
    // });


    // //smoothScroll
    // function smooth(target){
    //   var href = $(target).attr('href');
    //   var hH = $('.l-header').outerHeight();
    //   var top = $(href).offset().top - hH;
    //   animateoffset(top);
    // }
    // function animateoffset(top){
    //   $('html,body').animate({scrollTop:top}, 600);
    // }

    var pageTop = $('#pageTop');
    pageTop.hide();
    $(window).scroll(function () {
      if ($(this).scrollTop() > 650) {
        pageTop.fadeIn();
      } else {
        pageTop.fadeOut();
      }
    });
    pageTop.click(function () {
      $('body,html').animate({
        scrollTop: 0
      }, 500);
      return false;
    });

    $('a[href^="#"]').click(function(){
      var speed = 500;
      var headerHight = $('.l-header').outerHeight();
      var href= $(this).attr("href");
      var target = $(href == "#" || href == "" ? 'html' : href);
      var position = target.offset().top-headerHight;
      $("html, body").animate({scrollTop:position}, speed, "swing");
      return false;
    });

  });
  


  


  //アコーディオン
  jQuery(function ($) {
    $('.ac_content').hide();
    $('.ac_panel .ac_heading').on('click', function () {
        /*クリックでコンテンツを開閉*/
        $(this).next('.ac_content').slideToggle();
        /*矢印の向きを変更*/
        $(this).toggleClass('active');
    });
  });

  //アコーディオン
  jQuery(function ($) {
    $('.p-loanVoice_box_lower').hide();
    $('.p-loanVoice_box_upper .p-loanVoice_box_more').on('click', function () {
        /*クリックでコンテンツを開閉*/
        $(this).parents().find('.p-loanVoice_box_upper').next('.p-loanVoice_box_lower').slideToggle();
        /*矢印の向きを変更*/
        if ($(this).hasClass('active')) {
          $(this).toggleClass('active').find('.p-loanVoice_box_more_txt').text('もっと読む');
        }else{
          $(this).toggleClass('active').find('.p-loanVoice_box_more_txt').text('閉じる');
        }
    });
  });


  //タブ切り替え
  jQuery(function($) {
    $('.tab_head .head_cat').click(function() {
      var index = $('.tab_head .head_cat').index(this);
      $('.tab_head .head_cat').removeClass('current');
      $(this).addClass('current');
      $.when(
            $('.tab_body .body_cat').fadeOut()
        ).done(function() {
            $('.tab_body .body_cat').removeClass('show'),
            $.when(
                $('.tab_body .body_cat').eq(index).addClass('show')
            ).done(function() {
                $('.tab_body .body_cat').eq(index).fadeIn()
            })
        });
    });
  });


  // 同意 checkbox制御
  jQuery(function($) {
    $(".agreement_checkbox").change(function () {
      if ($(".agreement_01").prop("checked") && $(".agreement_02").prop("checked") && $(".agreement_03").prop("checked")) {
        $("#submit").attr("disabled",false);
        $("#submit").parent('.form_btn').removeClass('disabled');
      }else{
        $("#submit").attr("disabled",true);
        $("#submit").parent('.form_btn').addClass('disabled');
      }
    });
  });

  //////モーダル
	jQuery(function($) {
    var state = false;
    var scrollpos;
    var winScrollTop;
    $('.js-modal-open').each(function(){
        $(this).on('click',function(){
            winScrollTop = $(window).scrollTop();
            var target = $(this).data('target');
            var modal = document.getElementById(target);
            $(modal).fadeIn();
            //bodyfixed
            if(state == false) {
              scrollpos = $(window).scrollTop()
              $('body').addClass('fixed').css({'top': -scrollpos})
              state = true;
            } else {
                $('body').removeClass('fixed').css({'top': 0}),
                window.scrollTo( 0 , scrollpos )
                state = false;
            }
            return false;
        });
    });
    $('.js-modal-close').on('click',function(){
        $('.js-modal').fadeOut();
        $('body,html').stop().animate({scrollTop:winScrollTop}, 100);
        //bodyfixed
        if(state == false) {
          scrollpos = $(window).scrollTop()
          $('body').addClass('fixed').css({'top': -scrollpos})
          state = true;
        } else {
            $('body').removeClass('fixed').css({'top': 0}),
            window.scrollTo( 0 , scrollpos )
            state = false;
        }
        return false;
    });
  });

  (function() {
    var shareButton = document.getElementsByClassName("share");
    for (var i = 0; i < shareButton.length; i++) {
        shareButton[i].addEventListener("click", function(e) {
            e.preventDefault();
            window.open(this.href, "SNS_window", "width=600, height=500, menubar=no, toolbar=no, scrollbars=yes");
        }, false);
    }
  })()

}
